// src/components/MainScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const images = [
  'https://via.placeholder.com/150/FF0000/FFFFFF?Text=Image1',
  'https://via.placeholder.com/150/00FF00/FFFFFF?Text=Image2',
  'https://via.placeholder.com/150/0000FF/FFFFFF?Text=Image3',
  'https://via.placeholder.com/150/FFFF00/FFFFFF?Text=Image4',
  'https://via.placeholder.com/150/FF00FF/FFFFFF?Text=Image5',
  'https://via.placeholder.com/150/00FFFF/FFFFFF?Text=Image6',
];

const MainPage = () => {
  const navigate = useNavigate();

  const handleAddClick = () => {
    console.log('Navigating to /add-card');
    navigate('/add-card');
  };

  const handleImageClick = () => {
    console.log('Navigating to /new-page');
    navigate('/new-page');
  };

  const handleHomeClick = () => {
    navigate('/');
  };
  

  return (
    <div className="main-screen">
      <div className="top-bar">
        <div className="home-icon" onClick={handleHomeClick}>
          <img src="/main-logo.jpeg" alt="Home" className="custom-icon" />
        </div>           
        <div className="flags">
          <img src="/english-flag.png" alt="English" className="flag" />
          <img src="/german-flag.png" alt="German" className="flag" />
        </div>
      </div>
      <h1>My Words</h1>
      <div className="image-grid">
        <div className="image-item" onClick={handleAddClick}>
          <div className="add-icon">+</div>
        </div>
        {images.map((src, index) => (
          <div key={index} className="image-item" onClick={handleImageClick}>
            <img src={src} alt={`Random ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainPage;
