// src/Components/AddCard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

const AddCard = () => {
  const navigate = useNavigate();

  const [word, setWord] = useState('');
  const [translation, setTranslation] = useState('');

  const handleHomeClick = () => {
    navigate('/');
  };

  


  const handleImagineClick = async () => {
    try {
      const response = await fetch('http://localhost:8787', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ word, translation }),
      });
  
      if (response.ok) {
        alert('Word and translation added');
        setWord('');
        setTranslation('');
      } else {
        const errorText = await response.text();
        alert(`Failed to add word and translation: ${errorText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };
  




  

  return (
    <div className="add-card-container">
      <div className="top-bar">
        <div className="home-icon" onClick={handleHomeClick}>
          <img src="/main-logo.jpeg" alt="Home" className="custom-icon" />
        </div>        
        <div className="flags">
          <img src="/english-flag.png" alt="English" className="flag" />
          <img src="/german-flag.png" alt="German" className="flag" />
        </div>
      </div>

      <div className="card">
        <h2>Add New Card</h2>
        <p>Word to learn</p>
        <input
          type="text"
          placeholder="Enter text"
          className="input-field"
          value={word}
          onChange={(e) => setWord(e.target.value)}
        />        
        <p>Translation</p>
        <input
          type="text"
          placeholder="Enter text"
          className="input-field-translation"
          value={translation}
          onChange={(e) => setTranslation(e.target.value)}
        />
        <button className="imagine-button" onClick={handleImagineClick}>
          Imagine
        </button> 
        </div>
    </div>
  );
};

export default AddCard;
